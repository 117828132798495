import { Injectable } from '@angular/core';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../_base-shared/contracts/laravel-response.interface';
import { User } from '../../../../_base-shared/models/User/User';
import { ClientBaseApiService } from '../_shared/services/client-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientAuthService extends ClientBaseApiService {
  public login(user: User) {
    return this.http.post<LaravelResourceResponse<User>>(this.apiUrl + '/login', user).pipe(
      tap(response => this.globalEvents.setAuthUser(response.data)),
      catchError(response => this.handleError(response)),
    );
  }

  public newPassword(data) {
    return this.http.post<LaravelResourceResponse<User>>(`${ this.apiUrl }/auth/new-password`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public confirmResetPassword(data) {
    return this.http.post<LaravelResourceResponse<User>>(`${ this.apiUrl }/client/password/reset`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public forgotPassword(data) {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/client/password/id_card`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public checkLogin() {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/auth-user').pipe(
      tap(response => {
        this.globalEvents.setAuthUser(response.data);
      }),
      catchError(response => this.handleError(response)),
    );
  }

  public logout() {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/logout`, {})
      .pipe(
        catchError(response => this.handleError(response)),
        finalize(() => localStorage.setItem('splashScreen', '0')),
      );
  }

  public getUserByIdCard(idCard: number) {
    return this.http.post<LaravelResourceResponse<User>>(this.apiUrl + '/auth/get-user-by-dni', {id_card: idCard})
      .pipe(catchError(response => this.handleError(response)));
  }

  public sendAccessCode(idCard: number) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/auth/send-access-code', {id_card: idCard})
      .pipe(catchError(response => this.handleError(response)));
  }

  public checkAccessCode(requestData: { id_card: number, access_code: string }) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/auth/check-access-code', requestData)
      .pipe(catchError(response => this.handleError(response)));
  }

  public signIn(access_code: number, id_card: number, password: string, password_confirmation: string) {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/auth/confirm-account', {access_code, id_card, password, password_confirmation},
    ).pipe(catchError(response => this.handleError(response)));
  }
}
