import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from '../_shared/shared.module';
import { NavComponent } from './nav/nav.component';

@NgModule({
  imports:      [
    SharedModule,
    MatToolbarModule,
  ],
  declarations: [
    NavComponent,
  ],
  exports:      [
    NavComponent,
  ],
})
export class LayoutModule {
}
