import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { filter, finalize, map, mergeMap } from 'rxjs/operators';
import { Case } from '../../../_base-shared/models/Case/Case';
import { User } from '../../../_base-shared/models/User/User';
import { environment } from '../environments/environment';
import { ClientGlobalEventService } from './_shared/services/client-global-event.service';
import { ClientAuthService } from './client-auth/client-auth.service';

@Component({
  selector:    'app-root',
  templateUrl: './app.component.html',
  styles:      [],
})
export class AppComponent implements OnInit, OnDestroy {
  public currentTitle: string;
  public defaultTitle = environment.APP_NAME;
  public authUser: User;
  public clientSelectedCase: Case;
  public isLoading    = 0;

  private subscriptions: Array<Subscription> = [];
  private currentLanguage: 'en' | 'es';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              private clientAuth: ClientAuthService,
              private cookieService: CookieService,
              private translate: TranslateService,
              private globalEventsService: ClientGlobalEventService) {
    const storageLanguage = this.cookieService.get('lang');
    this.currentLanguage  = (storageLanguage === 'es' || storageLanguage === 'en') ? storageLanguage : 'es';
    this.translate.setDefaultLang(this.currentLanguage);
    this.cookieService.set('lang', this.currentLanguage, 365, '/');
  }

  ngOnInit(): void {
    this.defaultTitle = environment.APP_NAME;
    this.currentTitle = this.defaultTitle;
    this.watchRouterChanges();

    this.isLoading++;
    this.clientAuth.checkLogin()
      .pipe(finalize(() => this.isLoading--))
      .subscribe();  // Init client-auth user check

    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (!this.authUser) {
        return;
      }

      if (this.authUser.locale !== this.currentLanguage) {
        this.currentLanguage = this.authUser.locale;
        this.cookieService.set('lang', this.currentLanguage, 365, '/');
        this.translate.use(this.currentLanguage);
      }
    });
    this.globalEventsService.clientSelectedCase$.subscribe(selectedCase => {
      this.clientSelectedCase = selectedCase;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private watchRouterChanges(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe((routeData) => this.setTitle(routeData));
  }

  public setTitle(routeData: any): void {
    if (routeData.title) {
      this.currentTitle = this.defaultTitle + ' | ' + routeData.title;
    } else {
      this.currentTitle = this.defaultTitle;
    }

    this.titleService.setTitle(this.currentTitle);
  }
}
