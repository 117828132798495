<div class="payment-desktop-container client-typography d-flex flex-column justify-content-center">
  <div class="row d-flex flex-row justify-content-center align-items-center h-100">
        <div class="payment-methods-container">
          <h5 class="mt-3 mb-3 my-3">
            {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.heading' | translate }}
          </h5>
          <div class="payment-info pl-4 ps-4 py-2">
            <div class="heading d-flex flex-row justify-content-between w-100">
              <div class="d-flex flex-column mr-5 me-5">
                <span class="text-muted text-14">
                  {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.amount_due' | translate }}
                </span>
                <h3 class="payment-info-value">{{ amountToCharge | currency }}</h3>
              </div>
              <div *ngIf="dueDate" class="d-flex flex-column mr-4 me-4">
                <span class="text-muted text-14">
                  {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.date_due' | translate }}
                </span>
                <h3 class="payment-info-value">{{ dueDate | date:'d/M/y' }}</h3>
              </div>
            </div>
          </div>
          <form *ngIf="form && !showResponseHandler" [formGroup]="form" class="payment-methods mt-4"
                (ngSubmit)="submitForm()">
            <!-- Amount -->
            <app-input *ngIf="editableAmount" type="number" formControlName="amount" class=""
                       [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.charge_amount' | translate"
                       [fullWidth]="true" [showLabel]="false" [extraLabel]="true"
                       [appearance]="'standard'" [showClear]="false" suffix="&euro;">
            </app-input>
            <!-- Payment Method-->
            <mat-radio-group class="d-flex flex-column w-100" formControlName="payment_method_slug">
              <div class="card-method d-flex flex-row justify-content-between mb-3 pr-3 pe-3">
                <mat-radio-button value="card">
                  {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.card.label' | translate }}
                </mat-radio-button>
                <img src="assets/img/masterCardAndVisa.png" alt="" style="height: 23px;width: 81px;">
              </div>
              <div *ngIf="form?.get('payment_method_slug').value === 'card'">
                <mat-radio-group formControlName="selected_card_id">
                  <div class="existing-card p-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <mat-radio-button [value]="null">
                        {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.card.new_card' | translate }}
                      </mat-radio-button>
                      <img src="assets/img/masterCardAndVisa.png" alt="" style="height: 23px;width: 81px;">
                    </div>
                  </div>
                  <div *ngFor="let card of paymentCards" class="existing-card p-3">
                    <div class="d-flex justify-content-between">
                      <div>
                        <mat-radio-button [value]="card.id">{{ card.card_holder }}</mat-radio-button>
                        <p>
                          {{ card.card_bin }}** **** {{ card.card_last_four }} -
                          {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.card.existing_card.expiry' | translate }}
                          {{ card.card_exp_month }}/{{ card.card_exp_year }}
                          <small *ngIf="authUser?.role_id === 5">{{ card.payment_processor?.name }}</small>
                        </p>
                      </div>
                      <div>
                        <span *ngIf="card.default" class="badge" style="color: #549fd7">
                          {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.card.existing_card.default_label' | translate }}
                        </span>
                        <img *ngIf="card.card_brand?.toLowerCase() === 'visa'" src="assets/img/visaIcon.png"
                             alt="" style="height: 18px; width: 28px;">
                        <img *ngIf="card.card_brand?.toLowerCase() === 'master'" src="assets/img/masterCardIcon.png"
                             alt="" style="height: 18px; width: 28px;">
                        <button type="button" mat-icon-button
                                [matMenuTriggerFor]="menu" aria-label="Existing card menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button type="button" mat-menu-item [disabled]="!!card.default" class="text-center"
                                  (click)="makeDefault(card)">
                            <span>
                              {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.card.existing_card.menu.make_default' | translate }}
                            </span>
                          </button>
                          <button *ngIf="authUser?.role?.slug === 'super-admin' || (authUser?.id === 6 ||
                          authUser?.id === 130100 || authUser?.id ===  21 || authUser?.id === 415116 ||
                          authUser?.id === 130098 || authUser?.id === 235517 || authUser?.id === 22 ||
                          authUser?.id === 2497 || authUser?.id === 967)" type="button" mat-menu-item
                                  class="text-center" style="border-top: 1px solid lightgrey"
                                  (click)="deleteCard(card)">
                            <span style="color: #ed4f32">
                              {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.card.existing_card.menu.delete' | translate }}
                            </span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </mat-radio-group>
              </div>
              <div *ngIf="paymentProcessorSlug === 'redsys'"
                   class="bizum-method d-flex flex-row justify-content-between mt-3 mb-3 pr-3 pt-3 pb-3 py-3">
                <mat-radio-button value="bizum">
                  {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.bizum.label' | translate }}
                </mat-radio-button>
                <!-- <img src="assets/img/bizum.png" alt="" style="height: 40px;width: 78px;"> -->
              </div>
              <div *ngIf="form?.get('payment_method_slug').value === 'bizum'" formGroupName="bizum" class="mx-auto">
                <!-- First Name -->
                <app-input type="text" formControlName="first_name" class="text-small text-muted"
                           [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.bizum.first_name' | translate"
                           [fullWidth]="true" [showLabel]="false" [extraLabel]="true"
                           [appearance]="'standard'" [showClear]="false">
                </app-input>
                <!--  Last Name -->
                <app-input type="text" formControlName="last_name" class="text-small text-muted"
                           [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.bizum.last_name' | translate"
                           [fullWidth]="true" [showLabel]="false" [extraLabel]="true"
                           [appearance]="'standard'" [showClear]="false">
                </app-input>
                <!--  Phone -->
                <app-input type="number" formControlName="phone" class="text-small text-muted" prefix="+34"
                           [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.bizum.phone.label' | translate"
                           [fullWidth]="true" [showLabel]="false" [extraLabel]="true" [appearance]="'standard'"
                           [patternError]="'SHARED-COMPONENTS.payment_handler.payment_methods.bizum.phone.invalid_format' | translate"
                           [showClear]="false">
                </app-input>
              </div>
              <div *ngIf="authUser?.id === 1"
                   class="bizum-method d-flex flex-row justify-content-between mt-3 mb-3 pr-3 pt-3 pb-3 py-3">
                <mat-radio-button value="unnax">
                  Unnax
                </mat-radio-button>
              </div>
              <div *ngIf="form?.get('payment_method_slug').value === 'unnax'" formGroupName="unnax" class="mx-auto">
                <!-- First Name -->
                <app-input type="text" formControlName="first_name" class="text-small text-muted"
                           [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.bizum.first_name' | translate"
                           [fullWidth]="true" [showLabel]="false" [extraLabel]="true"
                           [appearance]="'standard'" [showClear]="false">
                </app-input>
                <!--  Last Name -->
                <app-input type="text" formControlName="last_name" class="text-small text-muted"
                           [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.bizum.last_name' | translate"
                           [fullWidth]="true" [showLabel]="false" [extraLabel]="true"
                           [appearance]="'standard'" [showClear]="false">
                </app-input>
              </div>
            </mat-radio-group>
            <div class="terms-and-conditions mt-3">
              <div class="save-card mt-3" *ngIf="form?.get('payment_method_slug').value === 'card'">
                <!--Save card-->
                <!-- <app-input *ngIf="!form.get('moto_payment').value" type="checkbox" formControlName="save_card" -->
                <!--            class="text-small text-muted" [extraLabel]="false" -->
                <!--            [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.card.save_card' | translate"> -->
                <!-- </app-input> -->
                <p *ngIf="!form.get('moto_payment').value" style="font-size: 12px; color: #000; margin-top: -18px;">
                  {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.card.save_card_info' | translate }}
                </p>
              </div>
              <!-- Privacy Policy -->
              <app-input type="checkbox" formControlName="privacy_policy" class="text-small text-muted mt-3"
                         [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.privacy_policy' | translate"
                         [extraLabel]="false" [clientTypography]="true">
              </app-input>
            </div>
            <div class="mx-auto">
              <button type="submit" class="pay-btn">
                {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.submit' | translate }}
              </button>
            </div>
          </form>

          <div [hidden]="!showResponseHandler" class="response-forms pl-4 ps-4 py-2 pr-4 pe-4">
            <form *ngIf="redirectConfirmForm" #paymentFormRef ngNoForm [action]="confirmFormTarget" method="POST"
                  [target]="redirectNewTab ? '_blank' : ''">
              <input type="hidden" name="Ds_SignatureVersion"
                     [value]="redirectConfirmForm?.get('signature_version').value">
              <input type="hidden" name="Ds_MerchantParameters"
                     [value]="redirectConfirmForm?.get('merchant_parameters').value">
              <input type="hidden" name="Ds_Signature" [value]="redirectConfirmForm?.get('signature').value">
              <div *ngIf="paymentProcessorSlug === 'redsys'" class="d-flex justify-content-between">
                <button mat-raised-button type="button" class="m-3" (click)="resetCardForm()">
                  {{ 'SHARED-COMPONENTS.payment_handler.payment_summary.cancel' | translate }}
                </button>
                <button mat-raised-button color="primary" type="submit" class="m-3">
                  {{ 'SHARED-COMPONENTS.payment_handler.payment_summary.submit' | translate }}
                </button>
              </div>
            </form>
            <form *ngIf="showRestCardForm && restCardForm" [formGroup]="restCardForm"
                  (ngSubmit)="submitNewCardPayment()">
              <div class="card-details row">
                <!-- <pre>{{ restCardForm.get('card_number').errors | json }}</pre> -->
                <!-- Card Brand -->
                <!-- {{ restCardForm.get('brand').value }} -->
                <!-- Card Number -->
                <div class="col-7 pb-3">
                  <app-input type="text" formControlName="card_number" appearance="outline"
                             [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.card.details.card_number.label' | translate"
                             [requiredStar]="true" [fullWidth]="true" [clientTypography]="false" [showClear]="false">
                  </app-input>
                  <mat-error class="pl-2" *ngIf="restCardForm.get('card_number').touched &&
                 !restCardForm.get('card_number').errors?.required && restCardForm.get('card_number').errors?.cardInvalid">
                    {{ "SHARED-COMPONENTS.payment_handler.payment_methods.card.details.card_number.errors.invalid_format" | translate }}
                  </mat-error>
                  <mat-error class="pl-2" *ngIf="restCardForm.get('card_number').touched &&
                 !restCardForm.get('card_number').errors?.required && restCardForm.get('brand').errors?.required">
                    {{ 'SHARED-COMPONENTS.payment_handler.payment_methods.card.details.brand.errors.invalid_type' | translate }}
                  </mat-error>
                </div>
                <div class="col-1 d-flex align-items-start pt-3">
                  <img *ngIf="restCardForm.get('brand').value === 'VISA'" class="img-fluid"
                       src="assets/img/visaIcon.png"
                       alt="">
                  <img *ngIf="restCardForm.get('brand').value === 'MASTERCARD'" class="img-fluid"
                       src="assets/img/masterCardIcon.png"
                       alt="">
                </div>
                <!-- Expiry Date -->
                <div class="col-4 pb-3">
                  <div class="w-100 d-flex justify-content-between align-items-start">
                    <div style="width: 45%">
                      <app-input type="text" formControlName="expiry_month" appearance="outline" class="mr-2"
                                 [requiredStar]="true" [clientTypography]="false"
                                 [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.card.details.exp_month'| translate"
                                 [extraLabel]="false" [fullWidth]="true" [placeholder]="'MM'" [showClear]="false">
                      </app-input>
                    </div>
                    <div style="width: 5%; padding-top: 20px">
                      <span class="font-weight-bold">/</span>
                    </div>
                    <div style="width: 45%">
                      <app-input type="text" formControlName="expiry_year" appearance="outline"
                                 [requiredStar]="true" [clientTypography]="false"
                                 [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.card.details.exp_year' | translate"
                                 [extraLabel]="false" [fullWidth]="true" [placeholder]="'YY'" [showClear]="false">
                      </app-input>
                    </div>
                  </div>
                </div>
                <!-- Card Holder -->
                <div class="col-8 pb-3">
                  <app-input type="text" formControlName="holder" appearance="outline" [requiredStar]="true"
                             [clientTypography]="false"
                             [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.card.details.card_holder' | translate"
                             [fullWidth]="true" [extraLabel]="false">
                  </app-input>
                </div>
                <!-- CVV -->
                <div class="col-4 pb-3">
                  <app-input type="text" formControlName="cvv" label="CVV" appearance="outline"
                             [requiredStar]="true" [clientTypography]="false" [showClear]="false"
                             [fullWidth]="true" [extraLabel]="false">
                  </app-input>
                </div>
                <!-- Set Default -->
                <div class="w-100 d-flex justify-content-end pr-4 pe-4">
                  <app-input type="checkbox" class="text-small" formControlName="set_default" appearance="outline"
                             [label]="'SHARED-COMPONENTS.payment_handler.payment_methods.card.existing_card.menu.make_default' | translate"
                             [requiredStar]="true" [clientTypography]="false"
                             [fullWidth]="true" [extraLabel]="false">
                  </app-input>
                </div>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center">
                <button mat-raised-button type="button" class="m-3" (click)="resetRestCardForm()">
                  {{ 'SHARED-COMPONENTS.payment_handler.payment_summary.cancel' | translate }}
                </button>
                <app-spinner-btn [loading]="isSubmittingNewCardViaRest" type="submit"
                                 [name]="'SHARED-COMPONENTS.payment_handler.payment_summary.submit' | translate">
                </app-spinner-btn>
              </div>
            </form>
            <div class="wpwl-container" #cardContainer>
              <form [hidden]="!showOppwaScriptForm" [action]="confirmFormTarget" class="paymentWidgets wpwl-container-card"
                    data-brands="VISA MASTER" lang="es">
              </form>
            </div>
          </div>
        </div>
  </div>
</div>
